import * as React from "react";
import "./RequestTheRights.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { 
  Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography,} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs, { Dayjs } from "dayjs";
import DatePicker from '@mui/lab/DatePicker';
import { Formik, FormikProps } from "formik";
import { ContactUs } from "../../../types/contactus.type";
import { useAppDispatch } from "../../..";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import { useState } from "react";
const Swal = require('sweetalert2')
const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));


const RequestTheRights: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const [selectedValue, setSelectedValue] = useState("");
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "left" },
    buttons: { marginTop: 2 },
  };

  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }


  const FormContactUs = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<ContactUs>) => {
    const handleClick = () => {
      const data = { indexData: 5 };
      navigate('/faq', { state: data });  // ส่งข้อมูลผ่าน state
    };
    
    return (
      <Grid> 
        <Grid container justifyContent="center">
          <Typography 
            sx={{
              fontSize: {
                xs: "22px",
                sm: "30px",
                md: "40px"
              },
              fontWeight: "500",
              color: "#d18250"
            }}
          >
                {t('Manage Your Data Rights')}
          </Typography>
          <Typography 
            sx={{
              fontSize: {
                xs: "14px",
                md: "18px"
              },
              fontWeight: "500",
              color: "#7c7a7a",
              textAlign: "center"
            }}
          >
                {t('Take control of your personal data with our simple form.')} &nbsp;
                <Link onClick={handleClick} target="_blank" rel="noreferrer"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      md: "18px"
                    },
                    fontWeight: "500",
                    color: "#7c7a7a",
                    textDecorationColor: "#7c7a7a",
                    ":hover" : {
                      color: "#d18250",
                      textDecorationColor: "#d18250",
                    }

                  }}
                >{t('What is this?')}</Link>
          </Typography>
        </Grid>
        <Grid  justifyContent="letf" sx={{ paddingTop: "50px"}}>
          <Grid  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography 
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#7c7a7a"
                }}
            >
                {t('Personal Information')}
            </Typography>
          </Grid>
          
        </Grid>
        <form onSubmit={handleSubmit} >
          <Grid 
            container
            sx={{
              paddingTop: "20px",
            }} 
            spacing={4}
          >
          
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}
 
            >
              <Typography  
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7c7a7a"
                }}
              >
                  {t('Name')}
              </Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                onChange={handleChange}
                value={values.name}
                autoComplete="email"
                autoFocus
                size="small"
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7c7a7a", // ✅ เปลี่ยนสีข้อความของ input
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography  
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7c7a7a"
                }}
              >
                {t('Email Address')}
              </Typography>

              <TextField
                variant="outlined"
                required
                fullWidth
                id="email_address"
                onChange={handleChange}
                value={values.email_address}
                size="small"
                helperText={t('ContactUs Email helperText')}
                sx={{ '.MuiFormHelperText-root': {
                    marginLeft: "0px",
                    fontSize: "10px", 
                  },
                  "& .MuiInputBase-input": {
                    color: "#7c7a7a", // ✅ เปลี่ยนสีข้อความของ input
                  },
                }}
                  
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography  
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7c7a7a"
                }}
              >
                  {t('Phone Number(Optional)')}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                id="contact_number"
                onChange={handleChange}
                value={values.contact_number}
                size="small"
                sx={{ '.MuiFormHelperText-root': {
                  marginLeft: "0px",
                  fontSize: "10px", 
                },
                "& .MuiInputBase-input": {
                  color: "#7c7a7a", // ✅ เปลี่ยนสีข้อความของ input
                },
              }}
                inputProps={{
                  maxLength: 13, // Thai phone numbers typically are 10 digits, +66 or 0
                }}
                onInput={(e) => {
                  // Cast e.target as HTMLInputElement to access .value
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9+]/g, "");
                }}
                onKeyPress={(e) => {
                  // Cast e.target as HTMLInputElement to access .value
                  const input = e.target as HTMLInputElement;
                  // ป้องกันการกดตัวอักษร
                  if (!/[0-9+]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                helperText={t('Example: +123456789')}
                error={!values.contact_number && !/^\+66\d{9}$/.test(values.contact_number) && !/^0\d{9}$/.test(values.contact_number)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography  
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7c7a7a"
                }}
              >
                  {t('Select Your Request')}
              </Typography>
              <Select
                size="small"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                sx={{
                  width: "100%",
                  "& .MuiSelect-select": {
                    color: "#7c7a7a", // เปลี่ยนสีข้อความที่เลือก
                  },
                  "& .MuiMenuItem-root": {
                    color: "#7c7a7a", // เปลี่ยนสีข้อความในตัวเลือก
                  },
                  "& .MuiSelect-icon": {
                    color: "#7c7a7a", // เปลี่ยนสีของ icon
                  },
                }}
              >
                <MenuItem value="Access information">{t(`Access information`)}</MenuItem>
                <MenuItem value="Update Information">{t(`Update Information`)}</MenuItem>
                <MenuItem value="Delete Account">{t(`Delete Account`)}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Typography  
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7c7a7a"
                }}
              >
                  {t('Details of Your Request')}
              </Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="message"
                value={values.message}
                onChange={handleChange}
                size="small"
                multiline
                rows={3}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7c7a7a", // ✅ เปลี่ยนสีข้อความของ input
                  },
                  
                }}
              />
            </Grid>
          </Grid>
          <Grid  container  sx={{paddingTop: "50px"}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography 
                sx={{
                  fontSize: {
                    xs: "14px",
                    md: "14px"
                  },
                  fontWeight: "500",
                  color: "#7c7a7a",
                  textAlign: "center"
                }}
              >
                  {t('By clicking submit, you confirm the information provided is accurate')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", justifyContent: "center", paddingTop: '20px'}}>
              <Button
                type="submit" 
                variant="contained" 
                sx={{ 
                  backgroundColor: '#bf896b', 
                  color:'#ffffff', 
                  margin: 'auto', 
                  width: '20%' , '&:hover': 
                  {
                    backgroundColor: '#d8a082'
                  },
                   textTransform: "none"
                }}
              >
                {t('Submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  };


  const initialValues: ContactUs = { name: "", email_address: "", contact_number: "", message: "" };
  return (
    <Container fixed sx={{ mt: 3, padding: "2rem 0" }} >

              
                <Grid container spacing={2} columns={12}   sx={{alignItems: "center"}} >
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async (values, { resetForm }) => {
                                if(selectedValue == ""){
                                  Swal.fire({
                                            title: `${t("Check Data")}`,
                                            text: `${t("Please Select Request")}`,
                                            icon: "warning",
                                        });  
                                        return;
                                }
                                dispatch(verifyEmailActions.sendMailRequestRights(values,selectedValue,navigate));
                                setSelectedValue("");
                                resetForm();
                            }}
                            
                        >
                        {(props) => FormContactUs(props)}
                        </Formik>
                    </Grid>
                </Grid>
  
      </Container>
  

  
  );
};

export default RequestTheRights;
