import * as React from "react";
import "./FAQPage.css";
import pdf from '../../layouts/assets/DCMPrivacyPolicy.pdf';
import i18next from 'i18next';
import { useAppDispatch } from "../../..";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
  Paper, 
  Grid,
  Container, 
  Box,
  Typography,
  TextField,
  IconButton
} from "@mui/material";
import { Description, Key, Search as SearchIcon, Tune } from "@mui/icons-material";
import { setIn } from "formik";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';

type FAQPageProps = {
  //
};

const FAQPage: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { indexData } = location.state || {};
  React.useEffect(() => {
    if (indexData !== undefined) {
      console.log("index: " + indexData)
      handleClickSubQuestions(4)
    }
  }, [indexData]);

  const [mainQuestions, setMainQuestions] = React.useState([
    {
      id: 99,
      questionsName: "",
      description: "",
    },
  ])

  const mainQuestionsEN = [
    {
      id: 0,
      questionsName: "SME Mate",
      description: "Who are we?",
    },
    {
      id: 1,
      questionsName: "Services",
      description: "What services does SME Mate offer?"
    },
    {
      id: 2,
      questionsName: "Hop On & Hop Off",
      description: "What is the Hop On & Hop Off service?",
    },
    {
      id: 3,
      questionsName: "Tools & Features",
      description: "What tools does SME Mate provide?",
    },
    {
      id: 4,
      questionsName: "Data Privacy and Protection",
      description: "what is our privacy policy?",
    },
  ]

  const mainQuestionsTH = [
    {
      id: 0,
      questionsName: "SME Mate",
      description: "เราเป็นใคร?",
    },
    {
      id: 1,
      questionsName: "บริการ",
      description: "SME Mate ให้บริการอะไรบ้าง?"
    },
    {
      id: 2,
      questionsName: "การจดทะเบียนธุรกิจ",
      description: "บริการ “การจดทะเบียนธุรกิจ” คืออะไร?",
    },
    {
      id: 3,
      questionsName: "เครื่องมือและคุณสมบัติ",
      description: "SME Mate มีเครื่องมืออะไรบ้าง?",
    },
    {
      id: 4,
      questionsName: "ความเป็นส่วนตัวและการคุ้มครองข้อมูล",
      description: "นโยบายความเป็นส่วนตัวของเรา คืออะไร?",
    },
  ]

  const subQuestionsEn = [
    [
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "What is SME Mate?",
        subQuestionDescription: "SME Mate is a business solutions provider founde d in 2024, dedicated to supporting small and medium-sized enterprises (SMEs) in Thailand. We offer innovative technology and expert services to help SMEs grow and navigate the challenges of the business world.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "How can SME Mate help my business grow?",
        subQuestionDescription: "Our experienced team offers personalized consulting and efficient document management, helping your business stay organized and freeing up your time to focus on growth. We work closely with you to identify solutions that align with your business needs.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "Where is SME Mate located?",
        subQuestionDescription: "Our office is located in Bangkok, Thailand, where our dedicated team operates to serve SMEs throughout the country.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "How can I contact SME Mate for support?",
        subQuestionDescription: "Our support team is available 24/7. You can submit requests through our online platform anytime, and our team will be ready to assist you within 24 hours.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "Why should I choose SME Mate?",
        subQuestionDescription: "SME Mate is dedicated exclusively to SMEs in Thailand, with a focus on providing seamless document management and consulting services. We understand the unique challenges faced by Thai SMEs and are committed to delivering solutions that help your business operate smoothly and efficiently.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "How does SME Mate support SME growth in Thailand?",
        subQuestionDescription: "SME Mate is dedicated to empowering small and medium-sized enterprises by offering expert knowledge, personalized support, and innovative tools designed to help SMEs succeed in today’s competitive landscape. Our focus is to be a reliable partner in your business journey, providing the resources you need to thrive.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "What makes SME Mate unique in supporting Thai SMEs?",
        subQuestionDescription: "Our services are tailored specifically for Thai SMEs. With expertise across various business domains, our team combines knowledge with cutting-edge tools and strategies that align with the unique challenges and needs of small businesses in Thailand.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "Who can benefit from SME Mate’s services?",
        subQuestionDescription: "SME Mate’s services are designed for small and medium enterprises in Thailand that are looking to streamline their operations, meet legal and regulatory requirements, and achieve sustainable growth.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "What is SME Mate’s commitment to business innovation?",
        subQuestionDescription: "SME Mate is committed to keeping your business competitive with the latest strategies and support. We continually innovate our services to align with the evolving needs of SMEs, making sure you have the resources to stay ahead.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "Services",
        subQuestionSubName: "What services does SME Mate offer?",
        subQuestionDescription: "SME Mate is a business solutions provider founded in 2024, dedicated to supporting small and medium-sized enterprises (SMEs) in Thailand. We offer innovative technology and expert services to help SMEs grow and navigate the challenges of the business world.",
        bullet:[
          {
            bulletName: "Business Consulting:",
            bulletDescription: "Tailored guidance and support to help businesses identify opportunities and overcome challenges",
          },
          {
            bulletName: "Document Management:",
            bulletDescription: "Assistance with administrative paperwork, including forms and essential documents, allowing business owners to focus on core operations.",
          },
          {
            bulletName: "24/7 Support:",
            bulletDescription: "Around-the-clock assistance with real-time request tracking.",
          },
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "How can I join SME Mate's services?",
        subQuestionDescription: "Joining SME Mate is easy. Register via our website or reach out directly via email to learn more about how we can support your business needs. Our team will guide you through the setup and discuss the best options for your business.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "How can I track my requests and receive updates?",
        subQuestionDescription: "Our platform allows real-time tracking of requests. Submit your requirements anytime, and our 24/7 support team will keep you updated throughout the process.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "What kind of expert support does SME Mate provide?",
        subQuestionDescription: "Our team offers personalized guidance and insights from seasoned business professionals. Whether you're facing specific business challenges or looking for general advice, our experts are here to help you achieve your business goals.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "How does SME Mate deliver personalized guidance?",
        subQuestionDescription: "We understand that every business is unique, so we provide individualized support to help you navigate your specific challenges and opportunities. Our team works closely with you, offering tailored recommendations and strategies for success.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: "What is the “Hop On & Hop Off” service?",
        subQuestionDescription: "Our “Hop On & Hop Off” service refers to our comprehensive, flexible approach to legal and business support for SMEs. SME Mate assists at every stage of your business journey, from formation and compliance to growth and eventual restructuring, ensuring you’re always legally protected.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `What are the "12 Stops" in SME Mate’s business support framework?`,
        subQuestionDescription: "Our 12 Stops cover every essential aspect of business operations and legal compliance, guiding businesses from formation to potential restructuring or dissolution. Each Stop addresses specific areas where legal compliance and business practices intersect, providing SMEs with structured support at each stage.",
        bullet:[],
        footterDescription: "The 12 Stops of SME Mate",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `What does SME Mate offer for Business Organization and Incorporation?`,
        subQuestionDescription: "SME Mate supports businesses with incorporation, partnership agreements (like joint ventures), and location establishment, ensuring all are compliant with Thai business laws. We help you understand legal requirements for structuring your business and setting up policies and objectives to minimize risks.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `How does SME Mate support Management and Employment compliance?`,
        subQuestionDescription: "We provide guidance on management practices, employment laws, and digital connectivity, ensuring that your business operates fairly and complies with labor and data protection laws. This includes assistance with hiring processes, employee pay, working conditions, and online data handling under PDPA.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `What legal support does SME Mate provide for Logistics and Suppliers?`,
        subQuestionDescription: "Our services include compliance support for product quality, safety, labeling, sourcing, and distribution regulations. SME Mate helps businesses meet legal requirements for materials sourcing and sustainability, so you can confidently market safe and compliant products.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `How does SME Mate assist with Sales and Marketing compliance?`,
        subQuestionDescription: "We ensure that your sales and marketing activities comply with consumer protection and advertising laws. This includes regulation of claims and disclosures, non-discriminatory targeting, and digital marketing practices under laws such as PDPA.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `What services are offered under Contracts and Risk Management?`,
        subQuestionDescription: "SME Mate supports businesses in creating enforceable contracts, managing collections, and maintaining transaction receipts. We guide you through debt recovery, record-keeping, and other legalities related to client and vendor interactions, reducing business risks.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `How does SME Mate help with Financial Compliance?`,
        subQuestionDescription: "We assist with tax filings, financial records, banking compliance, and corporate filings, ensuring your business adheres to tax codes and regulatory frameworks. This includes maintaining official documents like minutes books and stock certificates as required by law.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `What support does SME Mate provide for Intellectual Property Protection?`,
        subQuestionDescription: "We help businesses safeguard their brand identity, creative works, and patents, protecting the value of your R&D and innovation efforts. This includes trademark registration, copyright protection, and patent assistance.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `How does SME Mate assist with Mergers and Acquisitions?`,
        subQuestionDescription: "For businesses considering mergers or acquisitions, we provide legal guidance on protocols, acquisition processes, and due diligence. This ensures compliance and risk management when evaluating new partnerships or acquisition opportunities.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `What is included in Dispute Resolution support?`,
        subQuestionDescription: "We offer assistance with commercial disputes, litigation, and other resolution processes. SME Mate helps SMEs navigate legal proceedings and alternative dispute resolution methods to minimize disruptions.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `How does SME Mate assist with Business Evolution?`,
        subQuestionDescription: "Whether you’re expanding, restructuring, or managing debt, SME Mate provides legal guidance to facilitate growth. We assist with regulatory compliance for new business structures, expansion plans, and overcoming financial challenges.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "What is the DIY Business Calculations tool, and how can it benefit my business?",
        subQuestionDescription: "The DIY Business Calculations tool allows SMEs to analyze their financial health with ease. This powerful tool helps you calculate key metrics such as profitability, cash flow, and cost structures, giving you a clear picture of your business’s financial standing. By making it simple to run these calculations, SME Mate empowers you to make informed, strategic decisions that support growth and sustainability.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "How does the Document Request System simplify paperwork?",
        subQuestionDescription: "Our Document Request System is a ticketing portal designed for efficiency. You can submit, track, and manage document requests anytime. Whether you need registration documents, compliance paperwork, or any other business-related forms, the system organizes and tracks your requests, ensuring you have access to the documents you need without the hassle.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "Can I access my documents digitally with SME Mate?",
        subQuestionDescription: "Yes, SME Mate provides digital access to all your essential documents. You can log into your account, view, download, and even print documents directly from the platform. This makes it easy to access important files anytime, anywhere, streamlining administrative processes and allowing you to focus on core business operations",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "What resources does SME Mate offer for personalized support?",
        subQuestionDescription: "Our platform is staffed with seasoned business professionals who offer personalized insights and advice tailored to your business's specific needs. With this personalized support, you can navigate challenges, refine strategies, and access expert guidance on demand. We’re here to be your partner in growth, no matter what stage your business is in.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "What types of documents can I request through the Document Request Portal?",
        subQuestionDescription: "The Document Request Portal supports a wide range of documents, including:",
        bullet:[
          {
            bulletName: "Incorporation and Compliance Documents:",
            bulletDescription: "Business registration, tax compliance, and permits."
          },
          {
            bulletName: "Financial and Accounting Forms:",
            bulletDescription: "Tax filings, audit reports, and expense documents."
          },
          {
            bulletName: "Employee and HR Documentation:",
            bulletDescription: "Contracts, payroll forms, and workplace compliance paperwork."
          },
          {
            bulletName: "Contracts and Agreements:",
            bulletDescription: "Legal agreements, client contracts, and vendor documents."
          },
        ],
        footterDescription: "This broad scope ensures that all essential paperwork is easily accessible in one place.",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "How does SME Mate’s Download and Print feature work?",
        subQuestionDescription: "Once your requested documents are ready, you can download and print them directly from your SME Mate account. This feature provides immediate access to physical copies when you need them for meetings, compliance checks, or records.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "Can I track the progress of my requests in real-time?",
        subQuestionDescription: "Yes, our platform includes real-time tracking. When you submit a document or support request, you can monitor its status directly from your dashboard. This feature ensures transparency and keeps you informed about when to expect your requested documents or responses from our support team.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "Does SME Mate offer any tools for managing contracts and agreements?",
        subQuestionDescription: "Our platform includes resources to help with contract management, from drafting to record-keeping. SME Mate assists in creating enforceable contracts that comply with Thai law, ensuring you’re covered for business dealings and transactions. We also provide guidance on maintaining records and compliance for contracts, collection, and receipt management.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "How do I get access to these tools as an SME Mate member?",
        subQuestionDescription: "All tools are accessible via your SME Mate account. Once you’ve joined, you can log in to explore and use our advance DIY calculators, document portals, support systems, and more. Our user-friendly dashboard makes it easy to navigate each feature, and our support team is available to assist if you have any questions.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "What is the Personal Data Protection Act (PDPA)?",
        subQuestionDescription: "The PDPA is Thailand's data protection law that ensures your personal information is collected, stored, and processed securely and transparently. It gives you control over your personal data and outlines the responsibilities organizations must follow to protect it.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "What is personal data?",
        subQuestionDescription: "Personal data refers to any information that can identify you, either directly or indirectly. This includes, but is not limited to:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "Your name, address, phone number, or email."
          },
          {
            bulletName: "",
            bulletDescription: "Identification numbers, such as your ID card or passport."
          },
          {
            bulletName: "",
            bulletDescription: "Online identifiers, such as IP addresses and cookies."
          }
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "What personal data do you collect from me?",
        subQuestionDescription: "We may collect the following personal data, depending on your interaction with our website or services:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "Contact information (e.g., name, email, phone number)."
          },
          {
            bulletName: "",
            bulletDescription: "Account details (e.g., username, password)."
          },
          {
            bulletName: "",
            bulletDescription: "Browsing data (e.g., IP address, cookies, and analytics)."
          },
          {
            bulletName: "",
            bulletDescription: "Payment information, if applicable."
          }
        ],
        footterDescription: `You can learn more in our <a href='${pdf}'  target="_blank"   rel="noreferrer" >Privacy Policy<a/>`,
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "How do you use my personal data?",
        subQuestionDescription: "We use your personal data to:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "Provide and improve our services."
          },
          {
            bulletName: "",
            bulletDescription: "Respond to your inquiries or requests."
          },
          {
            bulletName: "",
            bulletDescription: "Process payments or transactions."
          },
          {
            bulletName: "",
            bulletDescription: "Send updates, promotions, or notifications (with your consent)."
          },
          {
            bulletName: "",
            bulletDescription: "Ensure compliance with legal and regulatory requirements."
          }
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "How do you protect my personal data?",
        subQuestionDescription: "We take your privacy seriously and implement strict security measures, such as:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "Encrypting your data during storage and transmission."
          },
          {
            bulletName: "",
            bulletDescription: "Restricting access to personal data to authorized personnel only."
          },
          {
            bulletName: "",
            bulletDescription: "Regularly reviewing our security protocols to prevent breaches."
          },
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "Will you share my personal data with others?",
        subQuestionDescription: "We may share your data with:",
        bullet:[
          {
            bulletName: "Service providers:",
            bulletDescription: "For purposes such as payment processing or email delivery."
          },
          {
            bulletName: "Regulatory authorities:",
            bulletDescription: "When required by law. We will never sell your personal data to third parties."
          },
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "What are my rights under the PDPA?",
        subQuestionDescription: "Under the PDPA, you have the right to:",
        bullet:[
          {
            bulletName: "Access your data:",
            bulletDescription: "Request a copy of your personal data."
          },
          {
            bulletName: "Correct your data:",
            bulletDescription: "Update inaccurate or incomplete information."
          },
          {
            bulletName: "Delete your data:",
            bulletDescription: "Request removal of your data, where applicable."
          },
        ],
        footterDescription: `You can exercise these rights by contacting us at <a href="mailto:admin@dcm.co.th" target="_blank" rel="noreferrer">admin@dcm.co.th</a> or fill in <a href='/my-data-rights-form'  target="_blank"   rel="noreferrer" >this form.<a/>`,
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "How can I manage cookies on your website?",
        subQuestionDescription: "You can manage cookies through your browser settings or by using the cookie preferences tool on our website. Keep in mind that disabling cookies may impact your experience.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "How long do you keep my data?",
        subQuestionDescription: `We retain your data only for as long as necessary to fulfill the purposes outlined in our <a href='${pdf}'  target="_blank"   rel="noreferrer" >Privacy Policy</a>, unless a longer retention period is required by law.`,
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "How can I contact you about my data privacy concerns?",
        subQuestionDescription: "If you have any questions or concerns regarding your personal data, please contact us at:",
        bullet:[
          {
            bulletName: `Email: <a href="mailto:admin@dcm.co.th" target="_blank" rel="noreferrer">admin@dcm.co.th</a>`,
            bulletDescription: ""
          },
          {
            bulletName: "Phone: (66) 2233-0068",
            bulletDescription: ""
          },
        ],
        footterDescription: "",
        opendata: false
      },
    ]
  ]

  const subQuestionsTH = [
    [
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "SME Mate คืออะไร?",
        subQuestionDescription: "SME Mate เป็นผู้ให้บริการโซลูชันธุรกิจ ก่อตั้งขึ้นในปี 2024 โดยมุ่งเน้นการสนับสนุนธุรกิจขนาดเล็กและขนาดกลาง (SME) ในประเทศไทย เรานำเสนอเทคโนโลยีนและบริการจากผู้เชี่ยวชาญเพื่อช่วยให้ SME เติบโตและเผชิญกับความท้าทายในโลกธุรกิจได้อย่างมั่นใจ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "SME Mate สามารถช่วยให้ธุรกิจของฉันเติบโตได้อย่างไร?",
        subQuestionDescription: "ทีมงานของเรามากไปด้วยประสบการณ์พร้อมที่จะให้คำปรึกษาเฉพาะบุคคลและช่วยจัดการเอกสารเพื่อช่วยให้คุณมีเวลาโฟกัสกับการเติบโตของธุรกิจของคุณได้อย่างเต็มที่ เราจะทำงานอย่างใกล้ชิดกับคุณ เพื่อช่วยดูแลและแก้ปัญหาธุรกิจของคุณโดยตรง",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "ที่ตั้งของ SME Mate อยู่ที่ไหน?",
        subQuestionDescription: "สำนักงานของเราตั้งอยู่ที่ กรุงเทพฯ ประเทศไทย ซึ่งทีมงานของเรามุ่งมั่นให้บริการแก่ SME ทั่วประเทศ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "ฉันจะติดต่อ SME Mate เพื่อขอรับการสนับสนุนได้อย่างไร?",
        subQuestionDescription: "ทีมสนับสนุนของเราพร้อมให้บริการตลอด 24 ชั่วโมงทุกวัน คุณสามารถส่งคำขอผ่านแพลตฟอร์มออนไลน์ได้ทุกเมื่อ และทีมงานของเราจะพร้อมให้ความช่วยเหลือภายใน 24 ชั่วโมง",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "ทำไมฉันควรเลือก SME Mate?",
        subQuestionDescription: "SME Mate มุ่งมั่นที่จะให้บริการเฉพาะสำหรับ SME ในประเทศไทย โดยเน้นการจัดการเอกสารที่ราบรื่นและการให้คำปรึกษาที่ครอบคลุม เราเข้าใจความท้าทายเฉพาะที่ SME ไทยเผชิญ และมุ่งมั่นที่จะนำเสนอโซลูชันที่ช่วยให้ธุรกิจของคุณดำเนินไปได้อย่างราบรื่นและมีประสิทธิภาพ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "SME Mate สนับสนุนการเติบโตของ SME ในประเทศไทยอย่างไร?",
        subQuestionDescription: "SME Mate ทุ่มเทในการเสริมสร้างศักยภาพให้กับ SME โดยมีความรู้จากผู้เชี่ยวชาญที่พร้อมสนับสนุนแตกต่างเฉพาะบุคคล  ซึ่งออกแบบมาเพื่อช่วยให้ SME ประสบความสำเร็จในการตลาดที่มีการแข่งขันสูง เป้าหมายของเราคือการเป็นพันธมิตรที่เชื่อถือได้ในเส้นทางธุรกิจของคุณ โดยพร้อมที่จะทุ่มเทและช่วยเหลือเพื่อให้ธุรกิจเติบโตอย่างยั่งยืน",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "สิ่งที่ทำให้ SME Mate โดดเด่นในการสนับสนุน SME ไทยคืออะไร?",
        subQuestionDescription: "บริการของเราปรับให้สอดคล้องกับความต้องการเฉพาะของ SME ไทย ด้วยความเชี่ยวชาญในหลากหลายด้านธุรกิจของทีมงานเรา ผสมผสานความรู้และกลยุทธ์ที่ทันสมัยเพื่อให้สอดคล้องกับความท้าทายและความต้องการเฉพาะของธุรกิจขนาดเล็กในประเทศไทย",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "ใครที่สามารถใช้บริการของ SME Mate ได้บ้าง?",
        subQuestionDescription: "บริการของ SME Mate ได้รับการออกแบบมาเพื่อธุรกิจขนาดเล็กและขนาดกลางในประเทศไทยที่ต้องการเพิ่มประสิทธิภาพในการดำเนินงาน ปฏิบัติตามกฎหมายและระเบียบข้อบังคับ เพื่อที่จะเติบโตอย่างยั่งยืนและถูกต้องตามกฎหมาย",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "SME Mate",
        subQuestionSubName: "ความมุ่งมั่นของ SME Mate ในการสร้างนวัตกรรมทางธุรกิจเป็นอย่างไร?",
        subQuestionDescription: "SME Mate มุ่งมั่นที่จะช่วยธุรกิจของคุณให้อยู่ในระดับแข่งขันได้ด้วยกลยุทธ์และการสนับสนุนจากเรา เราจะมีการนำเสนอบริการใหม่ ๆ อย่างต่อเนื่องเพื่อให้สอดคล้องกับความต้องการที่เปลี่ยนแปลงไปของ SME และมั่นใจว่าคุณมีทรัพยากรที่จำเป็นในการก้าวนำหน้าเสมอ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "บริการของเรา",
        subQuestionSubName: "SME Mate ให้บริการอะไรบ้าง?",
        subQuestionDescription: "เราให้บริการที่หลากหลายสำหรับ SME ซึ่งรวมถึง:",
        bullet:[
          {
            bulletName: "การให้คำปรึกษาทางธุรกิจ:",
            bulletDescription: "คำแนะนำและการสนับสนุนเฉพาะ เพื่อช่วยให้ธุรกิจระบุโอกาสและแก้ไขปัญหา",
          },
          {
            bulletName: "การจัดการเอกสาร:",
            bulletDescription: "ความช่วยเหลือในการจัดการงานเอกสาร รวมถึงแบบฟอร์มและเอกสารสำคัญ ช่วยให้คุณสามารถโฟกัสไปที่ธุรกิจของคุณได้อย่างเต็มที่",
          },
          {
            bulletName: "การสนับสนุนตลอด 24 ชั่วโมง:",
            bulletDescription: "บริการที่พร้อมให้บริการทุกเวลา พร้อมระบบติดตามสถานะคำขอแบบเรียลไทม์",
          },
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "ฉันจะเข้าร่วมบริการของ SME Mate ได้อย่างไร?",
        subQuestionDescription: "การเข้าร่วมกับ SME Mate นั้นง่ายมาก เพียงลงทะเบียนผ่านเว็บไซต์ของเรา หรือ ติดต่อทางอีเมลเพื่อเรียนรู้เพิ่มเติมเกี่ยวกับวิธีที่เราสามารถสนับสนุนความต้องการทางธุรกิจของคุณได้ ทีมงานของเราจะช่วยแนะนำขั้นตอนการเริ่มต้นและตัวเลือกที่เหมาะสมสำหรับธุรกิจของคุณ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "ฉันจะติดตามคำขอและรับการอัปเดตได้อย่างไร?",
        subQuestionDescription: "แพลตฟอร์มของเรามีระบบติดตามสถานะคำขอแบบเรียลไทม์ คุณสามารถส่งคำขอได้ทุกเมื่อ ทีมของเราจะตอบกลับภายใน 24 ชั่วโมง และ อัปเดตสถานะให้ทราบตลอดกระบวนการ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "SME Mate ให้การสนับสนุนจากผู้เชี่ยวชาญประเภทใดบ้าง?",
        subQuestionDescription: "ทีมงานของเรานำเสนอคำแนะนำเฉพาะบุคคลและข้อมูลเชิงลึกที่เหมาะกับธรุกิจของคุณ ไม่ว่าคุณจะเผชิญกับความท้าทายทางธุรกิจ หรือกำลังมองหาคำแนะนำทั่วไป ผู้เชี่ยวชาญของเราพร้อมช่วยให้คำแนะนำและปรึกษา",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Services",
        subQuestionSubName: "SME Mate ให้คำแนะนำเฉพาะบุคคลยังไง?",
        subQuestionDescription: "เราเข้าใจว่าธุรกิจแต่ละแห่งมีความเฉพาะตัว เราจึงให้การสนับสนุนที่ปรับแต่งเฉพาะแต่ละธุรกิจ เพื่อช่วยในการรับมือกับความท้าทายต่างๆ ทีมงานของเราจะทำงานใกล้ชิดกับคุณเพื่อให้คำแนะนำที่เหมาะสมเพื่อความสำเร็จของธุรกิจคุณ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "การจดทะเบียนธุรกิจ",
        subQuestionSubName: "บริการ “การจดทะเบียนธุรกิจ” คืออะไร?",
        subQuestionDescription: "บริการ “การจดทะเบียนธุรกิจ” ของเราเป็นแนวทางที่ครบวงจรและยืดหยุ่นในการสนับสนุนด้านกฎหมายและธุรกิจสำหรับ SME โดย SME Mate ให้การสนับสนุนในทุกขั้นตอนของการเดินทางธุรกิจของคุณ ตั้งแต่การก่อตั้งและการปฏิบัติตามกฎหมาย การเติบโต ไปจนถึงการปรับโครงสร้างธุรกิจ เพื่อให้คุณได้รับการคุ้มครองทางกฎหมายอย่างต่อเนื่อง",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `“12 สถานี” ในกรอบการสนับสนุนธุรกิจของ SME Mate คืออะไร?`,
        subQuestionDescription: "“12 สถานี” ครอบคลุมทุกแง่มุมสำคัญของการดำเนินธุรกิจและการปฏิบัติตามกฎหมาย ช่วยแนะนำธุรกิจตั้งแต่การก่อตั้งไปจนถึงการปรับโครงสร้างหรือยุบเลิก แต่ละสถานีจะเน้นเรื่องเฉพาะด้านที่เกี่ยวข้องกับการปฏิบัติตามกฎหมายและแนวทางธุรกิจที่ผสมผสานกัน เพื่อให้การสนับสนุนที่เป็นระบบในทุกขั้นตอน",
        bullet:[],
        footterDescription: "12 สถานี ของ SME Mate",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate มีบริการอะไรบ้างในด้านการจัดตั้งและการจดทะเบียนธุรกิจ?`,
        subQuestionDescription: "SME Mate ให้การสนับสนุนธุรกิจในการจดทะเบียน การทำข้อตกลงหุ้นส่วน (เช่น การร่วมทุน) และการจัดตั้งสถานที่ให้บริการ เพื่อให้ทุกอย่างเป็นไปตามกฎหมายธุรกิจของไทย เราช่วยให้คุณเข้าใจข้อกำหนดทางกฎหมายในการจัดโครงสร้างธุรกิจ และการตั้งนโยบายและเป้าหมายเพื่อลดความเสี่ยง",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate สนับสนุนการปฏิบัติตามกฎหมายด้านการจัดการและการจ้างงานอย่างไร?`,
        subQuestionDescription: "เรามีคำแนะนำด้านการจัดการ กฎหมายแรงงาน และการเชื่อมต่อดิจิทัล เพื่อให้ธุรกิจของคุณดำเนินไปอย่างยุติธรรมและเป็นไปตามกฎหมายแรงงานและกฎหมายคุ้มครองข้อมูล รวมถึงช่วยในกระบวนการจ้างงาน การจ่ายเงินเดือน การจัดสภาพการทำงาน และการจัดการข้อมูลออนไลน์ภายใต้ PDPA",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate ให้การสนับสนุนด้านกฎหมายสำหรับโลจิสติกส์และซัพพลายเออร์อย่างไร?`,
        subQuestionDescription: "บริการของเราครอบคลุมการสนับสนุนด้านการปฏิบัติตามข้อกำหนดเกี่ยวกับคุณภาพ ความปลอดภัย การติดฉลาก การจัดหาวัสดุ และกฎระเบียบด้านการจัดจำหน่าย SME Mate ช่วยให้ธุรกิจปฏิบัติตามข้อกำหนดทางกฎหมายในการจัดหาวัสดุและความยั่งยืน เพื่อให้คุณมั่นใจในการจำหน่ายผลิตภัณฑ์ที่ปลอดภัยและถูกกฎหมาย",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate ช่วยด้านการปฏิบัติตามกฎหมายในการขายและการตลาดอย่างไร?`,
        subQuestionDescription: "เราช่วยให้กิจกรรมการขายและการตลาดของคุณเป็นไปตามกฎหมายคุ้มครองผู้บริโภคและโฆษณา ซึ่งรวมถึงข้อบังคับในการเรียกร้องและการเปิดเผยข้อมูล การตลาดที่ไม่เลือกปฏิบัติ และการปฏิบัติทางการตลาดดิจิทัลภายใต้กฎหมาย เช่น PDPA",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate มีบริการอะไรในด้านการจัดการสัญญาและการบริหารความเสี่ยง?`,
        subQuestionDescription: "SME Mate ช่วยให้ธุรกิจสร้างสัญญาที่สามารถบังคับใช้ได้ การจัดการการเก็บหนี้ และการเก็บรักษาหลักฐานการทำธุรกรรม เราช่วยแนะนำในกระบวนการเรียกคืนหนี้ การเก็บรักษาข้อมูล และข้อกฎหมายที่เกี่ยวข้องกับการติดต่อกับลูกค้าและผู้ขายเพื่อลดความเสี่ยงทางธุรกิจ.",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate ช่วยด้านการปฏิบัติตามข้อกำหนดทางการเงินอย่างไร?`,
        subQuestionDescription: "เราช่วยเหลือในด้านการยื่นภาษี การเก็บบันทึกทางการเงิน การปฏิบัติตามข้อกำหนดทางธนาคาร และการยื่นเอกสารของบริษัท เพื่อให้ธุรกิจของคุณปฏิบัติตามรหัสภาษีและข้อบังคับต่าง ๆ ซึ่งรวมถึงการรักษาเอกสารทางการ เช่น บันทึกการประชุมและใบหุ้นตามที่กฎหมายกำหนด",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate ให้การสนับสนุนด้านการคุ้มครองทรัพย์สินทางปัญญาอย่างไร?`,
        subQuestionDescription: "เราช่วยธุรกิจในการปกป้องเอกลักษณ์ของแบรนด์ ผลงานสร้างสรรค์ และสิทธิบัตร เพื่อปกป้องมูลค่าของงานวิจัยและนวัตกรรมของคุณ ซึ่งรวมถึงการจดทะเบียนเครื่องหมายการค้า การคุ้มครองลิขสิทธิ์ และการให้ความช่วยเหลือในการจดสิทธิบัตร",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate ช่วยอย่างไรในการควบรวมกิจการ?`,
        subQuestionDescription: "สำหรับธุรกิจที่พิจารณาควบรวมกิจการ เราให้คำแนะนำด้านกฎหมายในเรื่องขั้นตอนการควบรวม การตรวจสอบวิเคราะห์สถานะ และการจัดการความเสี่ยง เพื่อให้มั่นใจว่าการประเมินความร่วมมือหรือโอกาสในการซื้อกิจการใหม่เป็นไปตามกฎหมาย",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: ` บริการช่วยเหลือในการระงับข้อพิพาทประกอบด้วยอะไรบ้าง?`,
        subQuestionDescription: "เรามีบริการช่วยเหลือในการจัดการข้อพิพาททางการค้า การดำเนินคดี และกระบวนการระงับข้อพิพาทอื่น ๆ SME Mate ช่วยให้ SME ผ่านกระบวนการทางกฎหมายและวิธีการระงับข้อพิพาททางเลือก เพื่อลดการหยุดชะงักในการดำเนินธุรกิจ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Hop on & Hop off",
        subQuestionSubName: `SME Mate ช่วยในการพัฒนาธุรกิจอย่างไร?`,
        subQuestionDescription: "ไม่ว่าคุณจะขยายธุรกิจ ปรับโครงสร้าง หรือต้องการจัดการหนี้สิน SME Mate ให้คำแนะนำด้านกฎหมายเพื่อส่งเสริมการเติบโตของธุรกิจ เราช่วยในการปฏิบัติตามกฎระเบียบสำหรับโครงสร้างธุรกิจใหม่ แผนการขยายธุรกิจ และการเอาชนะความท้าทายทางการเงิน",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "เครื่องมือและคุณสมบัติ",
        subQuestionSubName: "เครื่องมือ คำนวณธุรกิจด้วยตนเอง คืออะไร และมีประโยชน์ต่อธุรกิจของฉันอย่างไร?",
        subQuestionDescription: "เครื่องมือ คำนวณธุรกิจด้วยตนเอง ช่วยให้ SME วิเคราะห์สถานะทางการเงินของคุณได้อย่างง่ายดาย เครื่องมือนี้จะช่วยคำนวณตัวชี้วัดสำคัญ เช่น กำไร กระแสเงินสด และโครงสร้างต้นทุน ทำให้คุณมีภาพที่ชัดเจนเกี่ยวกับสถานะทางการเงินของธุรกิจ การคำนวณเหล่านี้จะช่วยให้คุณสามารถตัดสินใจได้ง่ายขึ้น",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "ระบบ การขอเอกสาร ช่วยทำให้การจัดการเอกสารง่ายขึ้นได้อย่างไร?",
        subQuestionDescription: "ระบบ การขอเอกสาร ของเราเป็นพอร์ทัลตั๋วที่ออกแบบมาเพื่อความสะดวกสบาย คุณสามารถส่ง ติดตาม และจัดการคำขอต่างๆได้ทุกเวลา ไม่ว่าจะเป็นเอกสารการจดทะเบียน เอกสารปฏิบัติตามกฎหมาย หรือแบบฟอร์มธุรกิจอื่น ๆ ระบบจะจัดระเบียบและติดตามคำขอของคุณเพื่อให้แน่ใจว่าคุณเข้าถึงเอกสารที่ต้องการได้โดยตลอดเวลา",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "ฉันสามารถเข้าถึงเอกสารของฉันกับ SME Mate ได้ตลอดเวลาหรือไม่?",
        subQuestionDescription: "คุณสามารถของถึงเอกสารของคุณได้ตลอดเวลา โดยล็อกอินเข้าบัญชีของคุณเพื่อดู ดาวน์โหลด และพิมพ์เอกสารโดยตรงจากแพลตฟอร์มของเราได้",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "SME Mate มีทรัพยากรอะไรบ้างสำหรับการสนับสนุนแบบเฉพาะบุคคล?",
        subQuestionDescription: "แพลตฟอร์มของเรามีผู้เชี่ยวชาญด้านธุรกิจที่มีประสบการณ์ซึ่งพร้อมให้คำแนะนำและปรึกษาเฉพาะบุคคลตามความต้องการของธุรกิจคุณ เราพร้อมเป็นพันธมิตรในเส้นทางการเติบโตของคุณไม่ว่าธุรกิจของคุณจะอยู่ในขั้นไหนก็ตาม",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "ฉันสามารถขอเอกสารประเภทใดได้บ้างผ่านระบบการขอคำขอ?",
        subQuestionDescription: "ระบบการขอคำขอ รองรับการขอเอกสารหลายประเภท เช่น:",
        bullet:[
          {
            bulletName: "เอกสารการจดทะเบียนและการปฏิบัติตามกฎหมาย:",
            bulletDescription: "การจดทะเบียนธุรกิจ การปฏิบัติตามข้อกำหนดภาษี และใบอนุญาตต่าง ๆ"
          },
          {
            bulletName: "แบบฟอร์มการเงินและบัญชี:",
            bulletDescription: "การยื่นภาษี รายงานการตรวจสอบบัญชี และเอกสารค่าใช้จ่าย"
          },
          {
            bulletName: "เอกสารสำหรับพนักงานและ HR:",
            bulletDescription: "สัญญาจ้าง แบบฟอร์มเงินเดือน และเอกสารปฏิบัติตามข้อกำหนดในสถานที่ทำงาน"
          },
          {
            bulletName: "สัญญาและข้อตกลง:",
            bulletDescription: "ข้อตกลงทางกฎหมาย สัญญากับลูกค้า และเอกสารผู้ขาย"
          },
        ],
        footterDescription: "ประเภทเอกสารที่ครอบคลุมนี้ช่วยให้เอกสารสำคัญทั้งหมดสามารถเข้าถึงได้ในที่เดียว",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "ฟีเจอร์ดาวน์โหลดและปริ้นของ SME Mate ทำงานอย่างไร?",
        subQuestionDescription: "เมื่อเอกสารที่คุณขอพร้อมใช้งาน คุณสามารถดาวน์โหลดและปริ้นได้โดยตรงจากบัญชี SME Mate ของคุณ ฟีเจอร์นี้ช่วยให้คุณเข้าถึงสำเนาเอกสารได้ทันทีเมื่อจำเป็นสำหรับการประชุม การตรวจสอบความถูกต้อง หรือการเก็บบันทึก",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "ฉันสามารถติดตามความคืบหน้าของคำขอแบบเรียลไทม์ได้หรือไม่?",
        subQuestionDescription: "คุณสามารถติดตามคำขอของคุณแบบเรียลไทม์ได้ เมื่อคุณส่งคำขอเอกสารหรือการสนับสนุน แพลตฟอร์มของเราจะมีระบบติดตามแบบเรียลไทม์ คุณสามารถตรวจสอบสถานะได้โดยตรงจากแดชบอร์ดของคุณ ฟีเจอร์นี้ช่วยให้คุณทราบสถานะของคำขอและคาดการณ์ได้ว่าจะได้รับเอกสารหรือคำตอบเมื่อใด",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "SME Mate มีเครื่องมือในการจัดการสัญญาและข้อตกลงหรือไม่?",
        subQuestionDescription: "แพลตฟอร์มของเรามีทรัพยากรสำหรับช่วยในการจัดการสัญญา ตั้งแต่การร่างสัญญาไปจนถึงการเก็บบันทึก SME Mate ช่วยให้ธุรกิจสร้างสัญญาที่สามารถบังคับใช้ได้และสอดคล้องกับกฎหมายไทย เพื่อให้คุณมั่นใจในข้อตกลงทางธุรกิจ เรายังให้คำแนะนำในการเก็บบันทึกและปฏิบัติตามข้อกำหนดสำหรับสัญญา การเรียกเก็บเงิน และการจัดการใบเสร็จ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Tools & Features",
        subQuestionSubName: "ฉันจะเข้าถึงเครื่องมือเหล่านี้ได้อย่างไรในฐานะสมาชิก SME Mate?",
        subQuestionDescription: "เครื่องมือทั้งหมดสามารถเข้าถึงได้ผ่านบัญชี SME Mate ของคุณ เมื่อสมัครสมาชิกแล้ว คุณสามารถล็อกอินเพื่อขอคำรอหรือใช้เครื่องมือของเรา เช่น เครื่องคิดเลขคำนวณธุรกิจด้วยตนเอง พอร์ทัลเอกสาร ระบบสนับสนุน และอื่น ๆ แพลตฟอร์มของเราจะช่วยให้คุณเข้าถึงแต่ละฟีเจอร์ได้ง่ายดายและมีทีมงานคอยสนับสนุนคุณตลอดเวลา",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
    ],
    [
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล (PDPA) คืออะไร?",
        subQuestionDescription: "พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล (PDPA) เป็นกฎหมายคุ้มครองข้อมูลของประเทศไทยที่มุ่งเน้นการรักษาความปลอดภัยและความโปร่งใสในการเก็บ รักษา และประมวลผลข้อมูลส่วนบุคคล โดยให้สิทธิ์คุณในการควบคุมข้อมูลส่วนบุคคลของตนเอง และกำหนดหน้าที่ที่องค์กรต้องปฏิบัติเพื่อปกป้องข้อมูลเหล่านั้น",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "ข้อมูลส่วนบุคคลคืออะไร?",
        subQuestionDescription: "ข้อมูลส่วนบุคคลหมายถึงข้อมูลใด ๆ ที่สามารถระบุตัวบุคคลได้โดยตรงหรือทางอ้อม เช่น:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "ชื่อ ที่อยู่ หมายเลขโทรศัพท์ หรืออีเมล"
          },
          {
            bulletName: "",
            bulletDescription: "หมายเลขประจำตัว เช่น หมายเลขบัตรประชาชนหรือหนังสือเดินทาง"
          },
          {
            bulletName: "",
            bulletDescription: "ตัวระบุออนไลน์ เช่น IP Address และ Cookies"
          }
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "คุณเก็บข้อมูลส่วนบุคคลอะไรจากฉันบ้าง?",
        subQuestionDescription: "ข้อมูลส่วนบุคคลที่เราอาจเก็บรวบรวม ขึ้นอยู่กับการใช้งานเว็บไซต์หรือบริการของคุณ เช่น:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "ข้อมูลติดต่อ (เช่น ชื่อ อีเมล หมายเลขโทรศัพท์)"
          },
          {
            bulletName: "",
            bulletDescription: "รายละเอียดบัญชี (เช่น ชื่อผู้ใช้และรหัสผ่าน)"
          },
          {
            bulletName: "",
            bulletDescription: "ข้อมูลการใช้งานเว็บไซต์ (เช่น IP Address, Cookies, การวิเคราะห์การใช้งาน)"
          },
          {
            bulletName: "",
            bulletDescription: "ข้อมูลการชำระเงิน (ถ้ามี)"
          }
        ],
        footterDescription: `คุณสามารถดูข้อมูลเพิ่มเติมได้ใน <a href='${pdf}'  target="_blank"   rel="noreferrer" >นโยบายความเป็นส่วนตัว<a/>`,
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "คุณใช้ข้อมูลส่วนบุคคลของฉันอย่างไร?",
        subQuestionDescription: "เราใช้ข้อมูลส่วนบุคคลของคุณเพื่อ:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "ให้บริการและพัฒนาบริการของเรา"
          },
          {
            bulletName: "",
            bulletDescription: "ตอบคำถามหรือดำเนินการตามคำขอของคุณ"
          },
          {
            bulletName: "",
            bulletDescription: "ดำเนินการชำระเงินหรือธุรกรรม"
          },
          {
            bulletName: "",
            bulletDescription: "ส่งข้อมูลอัปเดต โปรโมชั่น หรือการแจ้งเตือน (ตามที่คุณยินยอม)"
          },
          {
            bulletName: "",
            bulletDescription: "ปฏิบัติตามข้อกำหนดทางกฎหมายและกฎระเบียบ"
          }
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "คุณปกป้องข้อมูลส่วนบุคคลของฉันอย่างไร?",
        subQuestionDescription: "เราให้ความสำคัญกับความเป็นส่วนตัวของคุณและใช้มาตรการรักษาความปลอดภัยที่เข้มงวด เช่น:",
        bullet:[
          {
            bulletName: "",
            bulletDescription: "การเข้ารหัสข้อมูลทั้งในระหว่างการเก็บและส่งข้อมูล"
          },
          {
            bulletName: "",
            bulletDescription: "จำกัดการเข้าถึงข้อมูลส่วนบุคคลให้เฉพาะบุคคลที่ได้รับอนุญาตเท่านั้น"
          },
          {
            bulletName: "",
            bulletDescription: "ทบทวนโปรโตคอลความปลอดภัยของเราอย่างสม่ำเสมอเพื่อป้องกันการละเมิด"
          },
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "คุณจะแบ่งปันข้อมูลส่วนบุคคลของฉันกับใครบ้าง?",
        subQuestionDescription: "เราอาจแบ่งปันข้อมูลของคุณกับ:",
        bullet:[
          {
            bulletName: "ผู้ให้บริการ:",
            bulletDescription: "เช่น การดำเนินการชำระเงินหรือการส่งอีเมล"
          },
          {
            bulletName: "หน่วยงานที่มีอำนาจ:",
            bulletDescription: "เมื่อกฎหมายกำหนด เราจะไม่ขายข้อมูลส่วนบุคคลของคุณให้กับบุคคลที่สาม"
          },
        ],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "ฉันมีสิทธิอะไรบ้างภายใต้ PDPA?",
        subQuestionDescription: "ภายใต้ PDPA คุณมีสิทธิ:",
        bullet:[
          {
            bulletName: "เข้าถึงข้อมูลของคุณ:",
            bulletDescription: "ขอสำเนาข้อมูลส่วนบุคคลที่เรามี"
          },
          {
            bulletName: "แก้ไขข้อมูลของคุณ:",
            bulletDescription: "อัปเดตหรือแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่ครบถ้วน"
          },
          {
            bulletName: "ลบข้อมูลของคุณ:",
            bulletDescription: "ขอให้เราลบข้อมูลของคุณในกรณีที่เหมาะสม"
          },
        ],
        footterDescription: `คุณสามารถใช้สิทธิเหล่านี้ได้โดยติดต่อเราที่ <a href="mailto:admin@dcm.co.th" target="_blank" rel="noreferrer">admin@dcm.co.th</a> หรือ  <a href='/my-data-rights-form'  target="_blank"   rel="noreferrer" >กรอกแบบฟอร์ม<a/>`,
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "ฉันสามารถจัดการ Cookies บนเว็บไซต์ของคุณได้อย่างไร?",
        subQuestionDescription: "คุณสามารถจัดการ Cookies ได้ผ่านการตั้งค่าเบราว์เซอร์ของคุณ หรือใช้เครื่องมือการจัดการ Cookies บนเว็บไซต์ของเรา โปรดทราบว่าการปิดใช้งาน Cookies อาจมีผลกระทบต่อประสบการณ์การใช้งานเว็บไซต์ของคุณ",
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "คุณเก็บข้อมูลของฉันไว้นานแค่ไหน?",
        subQuestionDescription: `เราจะเก็บข้อมูลของคุณไว้ตราบเท่าที่จำเป็นเพื่อวัตถุประสงค์ที่ระบุไว้ใน<a href='${pdf}'  target="_blank"   rel="noreferrer" >นโยบายความเป็นส่วนตัว</a>ของเราหรือจนกว่ากฎหมายกำหนด`,
        bullet:[],
        footterDescription: "",
        opendata: false
      },
      {
        subQuestionsName: "Data Privacy and Protection",
        subQuestionSubName: "ฉันสามารถติดต่อคุณเกี่ยวกับข้อกังวลด้านความเป็นส่วนตัวของข้อมูลได้อย่างไร?",
        subQuestionDescription: "หากคุณมีคำถามหรือข้อกังวลเกี่ยวกับข้อมูลส่วนบุคคลของคุณ กรุณาติดต่อเราได้ที่:",
        bullet:[
          {
            bulletName: `อีเมล: <a href="mailto:admin@dcm.co.th" target="_blank" rel="noreferrer">admin@dcm.co.th</a>`,
            bulletDescription: ""
          },
          {
            bulletName: "โทรศัพท์: (66) 2233-0068",
            bulletDescription: ""
          },
        ],
        footterDescription: "",
        opendata: false
      },
    ]
  ]

  const [subQuestions, setSubQuestions] = React.useState([
    [
      {
        subQuestionsName: "",
        subQuestionSubName: "",
        subQuestionDescription: "",
        bullet:[
          {
            bulletName: "",
            bulletDescription: ""
          }
        ],
        footterDescription: "",
        opendata: false
      },
    ]
  ])

  const [openSubQuestions, setOpenSubQuestions] = React.useState<boolean>(false);
  const [indexSubQuestions, setIndexSubQuestions] = React.useState<number>(99);
  const [searchText, setSearchText] = React.useState("");
  const [searchSubText, setSearchSubText] = React.useState("");

  const handleClickSubQuestions = (index: number) => {
    setOpenSubQuestions(true);
    setIndexSubQuestions(index);
  };

  const handleSubSubQuestions = (index: number) => {
    setSubQuestions((prevSubQuestions) =>
      prevSubQuestions.map((questions, qIndex) =>
        qIndex === indexSubQuestions
          ? questions.map((subQuestion, sqIndex) =>
              sqIndex === index
                ? { ...subQuestion, opendata: !subQuestion.opendata }
                : subQuestion
            )
          : questions
      )
    );
  };
  
  const handleClickBack = () => {
    setOpenSubQuestions(false);
    setSubQuestions((prevSubQuestions) =>
      prevSubQuestions.map((questions) =>
        questions.map((subQuestion) => ({
          ...subQuestion,
          opendata: false
        }))
      )
    );
    if(i18next.language == 'en'){
      setMainQuestions(mainQuestionsEN)
      setSubQuestions(subQuestionsEn)
    }else if(i18next.language == 'th'){
      setMainQuestions(mainQuestionsTH)
      setSubQuestions(subQuestionsTH)
    }
    setSearchText("")
    setSearchSubText("")
  };
  
  const requestSearch = (searchValue: string) => {
    if(searchValue != ""){

      const searchRegex = new RegExp(`.*${searchValue}.*`, "i");
       
      if(i18next.language == 'en'){
        const filteredRows = mainQuestionsEN.filter((question) => 
          searchRegex.test(question.questionsName) || 
          searchRegex.test(question.description)
        );
        setMainQuestions(filteredRows); 
      }else if(i18next.language == 'th'){
        const filteredRows = mainQuestionsTH.filter((question) => 
          searchRegex.test(question.questionsName) || 
          searchRegex.test(question.description)
        );
        setMainQuestions(filteredRows); 
      }
    }else{
      if(i18next.language == 'en'){
        setMainQuestions(mainQuestionsEN)
      }else if(i18next.language == 'th'){
        setMainQuestions(mainQuestionsTH)
      }
    }
  };

  const requestSubSearch = (searchValue: string) => {
    if (searchValue != "") {
      const searchRegex = new RegExp(`.*${searchValue}.*`, "i");
  
      // หากภาษาเป็นภาษาอังกฤษ
      if (i18next.language == 'en') {
        const filteredRows = subQuestionsEn[indexSubQuestions].filter((questionGroup) =>
              searchRegex.test(questionGroup.subQuestionSubName) ||
              searchRegex.test(questionGroup.subQuestionDescription) ||
              searchRegex.test(questionGroup.footterDescription) ||
              questionGroup.bullet.some((bullet) =>
                searchRegex.test(bullet.bulletName) || searchRegex.test(bullet.bulletDescription)
              )
          );
        
        const updatedSubQuestions = [...subQuestionsEn]; 
        updatedSubQuestions[indexSubQuestions] = filteredRows;
        setSubQuestions(updatedSubQuestions);

      } else if (i18next.language == 'th') {
        const filteredRows = subQuestionsTH[indexSubQuestions].filter((questionGroup) =>
          searchRegex.test(questionGroup.subQuestionSubName) ||
          searchRegex.test(questionGroup.subQuestionDescription) ||
          searchRegex.test(questionGroup.footterDescription) ||
          questionGroup.bullet.some((bullet) =>
            searchRegex.test(bullet.bulletName) || searchRegex.test(bullet.bulletDescription)
          )
        );
      
        const updatedSubQuestions = [...subQuestionsEn]; 
        updatedSubQuestions[indexSubQuestions] = filteredRows;
        setSubQuestions(updatedSubQuestions);
      }
    } else {
      // หากไม่มีคำค้น ให้แสดงข้อมูลทั้งหมด
      if (i18next.language == 'en') {
        setSubQuestions(subQuestionsEn);
      } else if (i18next.language == 'th') {
        setSubQuestions(subQuestionsTH);
      }
    }
  };

  React.useEffect(() => {
    setSearchText("")
    setSearchSubText("")
    if(i18next.language == 'en'){
      setMainQuestions(mainQuestionsEN)
      setSubQuestions(subQuestionsEn)
    }else if(i18next.language == 'th'){
      setMainQuestions(mainQuestionsTH)
      setSubQuestions(subQuestionsTH)
    }
  }, [i18next.language]);
  
  return (
    <Container>
      <Grid className="faq-container" container alignItems="center" justifyContent="center">
        {
          openSubQuestions && (
            <Grid container className="faq-back-container" >
              <ArrowBackIosNewIcon 
                onClick={() => handleClickBack()}
                sx={{ 
                  fontSize: 35, 
                  cursor: "pointer",
                  "&:hover": {
                    color: "#c6c6c6", // สีที่ต้องการเมื่อ hover
                  },
                  zIndex: 10,    
                }}
              />
            </Grid>
          )
        }
        { 
          !openSubQuestions ? (
            <>    
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography className="heading-text-faq">
                    {t(`Frequently Asked Questions`)}
                  </Typography>
                </Grid>
                <Grid item className="faq-search-container">
                  <TextField 
                    value={searchText}
                    className="text-search"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (<IconButton><SearchIcon /></IconButton>)
                    }}
                    onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                    }} 
                  />
                </Grid>
              </Grid>
              <Grid container>
              {
                mainQuestions.map((questions, index) => (
                  <>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
                    <Grid 
                      key={index}
                      className="d-flex faq-sub-container" 
                      item xs={11} sm={11} md={5} lg={5} xl={5} 
                      onClick={() => handleClickSubQuestions(questions.id)}
                    >
                      <Grid 
                        container
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          flexWrap: 'nowrap' 
                        }}
                        className="faq-sub-container-icon"
                      >
                        <Typography className="sub-heading-text-faq">
                          {questions.questionsName}
                        </Typography>
                        <PlayArrowIcon 
                          sx={{ 
                            fontSize: 30, 
                            cursor: "pointer",
                            "&:hover": {
                              color: "#c6c6c6", // สีที่ต้องการเมื่อ hover
                            },
                            zIndex: 10,  
                            flexWrap: 'nowrap'   
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Typography className="content-text-faq">
                          {questions.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))
              }
              </Grid>
            </>
          ):(
            <>
            
              <Grid container direction="column" alignItems="center" sx={{ margin: !openSubQuestions? "0" : "-35px 0 0 0" }}>
                <Grid item>
                  <Typography className="heading-text-faq">
                    {t(`${mainQuestionsEN[indexSubQuestions].questionsName}`)}
                  </Typography>
                </Grid>
                <Grid item className="faq-search-container">
                  <TextField 
                    value={searchSubText}
                    className="text-search"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (<IconButton><SearchIcon /></IconButton>)
                    }}
                    onChange={(e)=>{
                      setSearchSubText(e.target.value)
                      requestSubSearch(e.target.value)
                    }} 
                  />
                </Grid>
              </Grid>
                    { 
                    
                      subQuestions[indexSubQuestions]?.map((subuestions:any, index:any) => (
                        <>
                        <Grid container  onClick={() => handleSubSubQuestions(index)} key={index}>
                            <Grid 
                              className="d-flex faq-sub-sub-container" 
                              item xs={12} sm={12} md={10} lg={10} xl={10} 
                              sx={{ margin: "0 auto 0 auto"}}
                            >
                              <Grid 
                                container 
                                sx={{ 
                                  display: 'flex', 
                                  alignItems: 'start', 
                                  justifyContent: 'space-between', 
                                  flexWrap: 'nowrap' 
                                }}
                              >
                                {/* subQuestionSubName */}
                                <Typography 
                                  className="sub-heading-text-faq" 
                                  sx={{ 
                                    flexGrow: 1, // ทำให้ Typography ขยายพื้นที่เท่าที่มี
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', // ใส่จุด ... หากข้อความยาวเกิน
                                  }}
                                >
                                  {subuestions.subQuestionSubName}
                                </Typography>

                                {
                                  subuestions.opendata ? (
                                    <>
                                    <CloseIcon 
                                      sx={{ 
                                        fontSize: 35, 
                                        cursor: "pointer", 
                                        "&:hover": { color: "#c6c6c6" },
                                        marginLeft: '10px', // ระยะห่างระหว่างข้อความและไอคอน
                                      }}
                                    />
                                    </>
                                  ):(
                                    <>
                                      <AddIcon 
                                        sx={{ 
                                          fontSize: 35, 
                                          cursor: "pointer", 
                                          "&:hover": { color: "#c6c6c6" },
                                          marginLeft: '10px', // ระยะห่างระหว่างข้อความและไอคอน
                                        }}
                                      />
                                    </>
                                  )
                                }
                              </Grid>
                              {
                                  subuestions.opendata && (
                                    <>
                                      <Grid className="sub-question-description-container">
                                        <Typography 
                                          className="content-text-faq" 
                                          sx={{      
                                            "& a": {
                                                textDecoration: "none !important",
                                                fontWeight: "600 !important",
                                                color: "#212121 !important",
                                                ":hover" : {
                                                  color: "#c6c6c6 !important",
                                                }

                                              }
                                          }}
                                          dangerouslySetInnerHTML={{ __html: subuestions.subQuestionDescription }} 
                                        />
                                      </Grid>
                                      <Grid>
                                        {
                                          subuestions.bullet.length > 0 && subuestions.bullet.map((bullet: any, indexBullet: any) => (
                                            <>
                                              <Grid 
                                                className="faq-bullet-container" 
                                                key={indexBullet} 
                                                sx={{ 
                                                    display: 'flex', 
                                                    alignItems: 'start',
                                                  }}
                                                >

                                                <span>•</span>
                                                <Typography 
                                                  className="content-text-bullet-faq" 
                                                  sx={{ 
                                                    marginLeft: "8px",
                                                    fontWeight: "600",
                                                    whiteSpace: 'nowrap', 
                                                    "& a": {
                                                      textDecoration: "none !important",
                                                      fontWeight: "600 !important",
                                                      color: "#212121 !important",
                                                      ":hover" : {
                                                        color: "#c6c6c6 !important",
                                                      }
      
                                                    }
                                                  }} 
                                                  dangerouslySetInnerHTML={{ __html: bullet.bulletName }} 
                                                />
                                                <Typography className="content-text-bullet-faq" sx={{ marginLeft: "8px"}}>
                                                 {bullet.bulletDescription}
                                                </Typography>
                                              </Grid>
                                            </>
                                          ))
                                        }
                                      </Grid>
                                      <Grid className="content-text-bullet-faq" sx={{ paddingTop: "10px"}}>
                                        <Typography 
                                          className="content-text-faq" 
                                          sx={{
                                            color: "#212121",
                                            "& a": {
                                                textDecoration: "none !important",
                                                fontWeight: "600 !important",
                                                color: "#212121 !important",
                                                ":hover" : {
                                                  color: "#c6c6c6 !important",
                                                }

                                              }
                                          }} 
                                          dangerouslySetInnerHTML={{ __html: subuestions.footterDescription }} 
                                        />
                                      </Grid> 
                                    </>
                                  )
                              }
                            </Grid>
                          </Grid>
                        </>
                      ))
                    }

            </>
          )
        }
      </Grid>
    </Container>
  );
};

export default FAQPage;
